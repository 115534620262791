<template>
  <a-card :bordered="false">
    <a-space align="center" :size="30">
      <a-space align="center">
        <a-select
          v-model="enabled"
          placeholder="选择状态"
          style="width: 222px;"
        >
          <a-select-option value=""> 全部 </a-select-option>
          <a-select-option value="true"> 上架 </a-select-option>
          <a-select-option value="false">
            下架
          </a-select-option>
        </a-select>
        <a-button :loading="refreshLoading" type="primary" @click="query">
          搜索
        </a-button>
      </a-space>

      <a-space>
        <a-input
          v-model="userUid"
          style="width: 222px;"
          allow-clear
          placeholder="输入uid"
        />
        <a-button
          :loading="appendLoading"
          type="primary"
          @click="appendStoreForUid"
        >
          新增仓库
        </a-button>
      </a-space>

      <a-space>
        <a-input
          v-model="comment"
          style="width: 222px;"
          placeholder="备注筛选"
          allow-clear
        />
        <a-button type="primary" @click="filterData">
          筛选
        </a-button>
      </a-space>
    </a-space>
    <a-table
      style="margin-top: 10px;"
      rowKey="id"
      bordered
      :loading="refreshLoading"
      :dataSource="dataSource"
      :columns="columns"
      :pagination="false"
    >
      <template slot="sort" slot-scope="text, record">
        <span v-if="!editObj[record.id]">
          {{ text }}
          <a-icon type="edit" @click="addEdit(record)" />
        </span>
        <a-space direction="vertical" v-else>
          <a-input v-model="editObj[record.id].sort" style="width: 60px;" />
          <a-space>
            <a @click="cancelEdit(record.id)">取消</a>
            <span style="margin: 0 2px;">|</span>
            <a @click="editAdr(record.id)">确认</a>
          </a-space>
        </a-space>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-space>
          <a @click="openComponent('Base', record)">修改</a>
          <a @click="openComponent('Logistics', record)">快递公司</a>
          <a @click="openComponent('Premium', record)">溢价</a>
          <a @click="openComponent('GoodsList', record)">商品</a>
          <a-popconfirm title="确定删除？" @confirm="removeStore(record.id)">
            <a style="color: red">删除</a>
          </a-popconfirm>
        </a-space>
      </template>
    </a-table>
    <a-modal
      v-if="winVisible"
      :title="title"
      :visible="winVisible"
      :footer="null"
      width="80%"
      @cancel="winVisible = false"
    >
      <component
        :is="curComponent"
        :admin="true"
        :uid="curStore.uid"
        :curItem="curStore"
        @success="
          () => {
            winVisible = false;
            query();
          }
        "
      ></component>
    </a-modal>
  </a-card>
</template>
<script>
import Logistics from '@/views/address/logistics.vue'
import Premium from '@/views/address/premium.vue'
import GoodsList from '@/views/goods/list.vue'
import Base from '@/views/address/base.vue'
import {
  queryStoreList,
  addressUpdate,
  removeStoreApi,
  queryAndInsertStoreInfo
} from '@/api'
import dayjs from 'dayjs'
import { platformType } from '@/utils/util'
const titleObj = {
  Logistics: '快递公司',
  Premium: '溢价',
  GoodsList: '商品',
  Base: '仓库信息修改'
}
export default {
  components: {
    Logistics,
    Premium,
    GoodsList,
    Base
  },
  data () {
    return {
      dataSource: [],
      tempDataSource: [], // 保留数据
      columns: [
        {
          title: '排序',
          dataIndex: 'sort',
          scopedSlots: { customRender: 'sort' },
          width: '8%',
          align: 'center'
        },
        {
          title: 'uid',
          dataIndex: 'uid',
          width: '17%'
        },
        {
          title: '仓库名称',
          dataIndex: 'name',
          width: '15%'
        },
        {
          title: '平台',
          dataIndex: 'platform',
          customRender: data => {
            const temp = JSON.parse(data)
            return temp.reduce((sum, val, index) => {
              sum +=
                platformType[val] + (index === temp.length - 1 ? '' : '、')
              return sum
            }, '')
          },
          width: '20%'
        },
        {
          title: '快递',
          dataIndex: 'waybillCompany',
          width: '5%'
        },
        {
          title: '备注',
          dataIndex: 'comment',
          width: '8%'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          customRender: data => dayjs(data).format('YYYY-MM-DD HH:mm:ss'),
          width: '10%'
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'operation' },
          width: '20%',
          align: 'center'
        }
      ],
      winVisible: false,
      refreshLoading: false,
      curComponent: '',
      curStoreUid: '',
      title: '',
      /** 上架状态 */
      enabled: 'true',
      /** 新增仓库的用户UID */
      userUid: '',
      appendLoading: false,
      editObj: {},
      comment: '' // 备注查询
    }
  },
  created () {
    this.query()
  },
  methods: {
    async query () {
      this.refreshLoading = true
      const res = await queryStoreList({ enabled: this.enabled })
      this.refreshLoading = false
      if (res.code === 0) {
        this.dataSource = res.data
        this.tempDataSource = JSON.parse(JSON.stringify(res.data))
      }
      console.log('[res] ---> ', res)
    },
    openComponent (key, record) {
      this.curStore = JSON.parse(JSON.stringify(record))
      this.curComponent = key
      this.winVisible = true
      this.title = titleObj[key]
    },
    addEdit (record) {
      this.$set(this.editObj, record.id, JSON.parse(JSON.stringify(record)))
    },
    cancelEdit (id) {
      this.$set(this.editObj, id, null)
    },
    async editAdr (id) {
      const res = await addressUpdate({ ...this.editObj[id], admin: true })
      if (res.code === 0) {
        this.$message.success('修改成功')
        this.$set(this.editObj, id, null)
        this.query()
      } else {
        this.$message.error(res.msg)
      }
    },
    async removeStore (id) {
      const res = await removeStoreApi(id)
      if (res.code === 0) {
        this.$message.success('删除成功')
        this.query()
      } else {
        this.$message.error(res.msg)
      }
    },
    async appendStoreForUid () {
      if (!this.userUid) {
        return this.$message.error('请输入UID后再点击新增仓库')
      }
      this.appendLoading = true
      const res = await queryAndInsertStoreInfo(this.userUid)
      if (res.code === 0) {
        this.openComponent('Base', res.data)
      }
      this.appendLoading = false
    },
    filterData () {
      const data = JSON.parse(JSON.stringify(this.tempDataSource))
      if (!this.comment) {
        this.dataSource = data
      } else {
        this.dataSource = data.filter(val =>
          val.comment.includes(this.comment)
        )
      }
    }
  }
}
</script>

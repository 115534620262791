<template>
  <page-view :title="title">
    <a-card :bordered="false">
      <a-row :gutter="48">
        <a-col :md="3" :sm="24">
          <a-input placeholder="省" v-model="queryParam.province" />
        </a-col>
        <a-col :md="3" :sm="24">
          <a-input placeholder="市" v-model="queryParam.city" />
        </a-col>
        <a-col :md="3" :sm="24">
          <a-input placeholder="县" v-model="queryParam.county" />
        </a-col>
        <a-col :md="2" :sm="0">
          <a-button
            class="editable-add-btn"
            @click="$refs.table.refresh(false)"
          >查询</a-button
          >
        </a-col>
        <a-col :md="2" :sm="0">
          <a-button
            style="margin-left: 8px;"
            class="editable-add-btn"
            @click="insert"
          >新增</a-button
          >
        </a-col>
      </a-row>
      <s-table
        style="margin-top: 10px"
        ref="table"
        rowKey="id"
        :columns="columns"
        :data="dataSource"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <template slot="send" slot-scope="text, record">
          <a-switch
            checkedChildren="是"
            unCheckedChildren="否"
            :checked="text"
            @change="updateSend(record)"
          />
        </template>
        <template slot="enable" slot-scope="text, record">
          <a-switch
            checkedChildren="开"
            unCheckedChildren="关"
            :checked="text"
            @change="updateEnable(record)"
          />
        </template>
        <template slot="operation" slot-scope="text, record">
          <a @click="update(record)">修改</a>
          <a style="margin-left: 8px;" @click="deleteRecord(record)">删除</a>
        </template>
      </s-table>
    </a-card>
    <a-modal
      title="编辑"
      :width="640"
      :visible="edit.show"
      v-if="edit.show"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <p>请注意：用户设置的省市县地址与设置信息相似全部溢价，请务必填好</p>
      <p>请注意：只根据省市县里面的填写的最后一级匹配</p>
      <!--<a-input class="top5" placeholder="平台" v-model="edit.platformType" />-->
      <a-input class="top5" placeholder="省" v-model="edit.province" />
      <a-input class="top5" placeholder="市" v-model="edit.city" />
      <a-input class="top5" placeholder="县" v-model="edit.county" />
      <a-input-number
        class="top5"
        style="width: 100%;"
        :precision="2"
        placeholder="溢价"
        v-model="edit.price"
      />
    </a-modal>
  </page-view>
</template>
<script>
import { STable } from '@/components'
import { PageView } from '@/layouts'
import { premiumList, premiumInsert, premiumUpdate, premiumDelete } from '@/api'

export default {
  /** 管理员操作时候调用该组件会传 admin uid仓库id */
  props: {
    admin: {
      type: Boolean,
      default: false
    },
    uid: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      edit: { show: false },
      queryParam: {},
      columns: [
        { title: '#', scopedSlots: { customRender: 'serial' } },
        { title: '省', dataIndex: 'province' },
        { title: '市', dataIndex: 'city' },
        { title: '县', dataIndex: 'county' },
        { title: '溢价', dataIndex: 'price' },
        {
          title: '暂停发货',
          dataIndex: 'send',
          scopedSlots: { customRender: 'send' }
        },
        {
          title: '开关',
          dataIndex: 'enable',
          scopedSlots: { customRender: 'enable' }
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      dataSource: (parameter) => {
        const extra = {
          admin: this.admin || undefined,
          uid: this.uid || undefined
        }
        return premiumList(Object.assign(parameter, this.queryParam, extra)).then(
          (result) => {
            return result.data
          }
        )
      }
    }
  },
  created () {},
  components: {
    PageView,
    STable
  },
  methods: {
    update (record) {
      record.show = true
      this.edit = JSON.parse(JSON.stringify(record))
    },
    deleteRecord (record) {
      premiumDelete(record.id).then((res) => {
        this.handleRes(res)
      })
    },
    insert () {
      this.edit = { show: true }
    },
    handleCancel () {
      this.edit = { show: false }
    },
    updateSend (record) {
      record.send = !record.send
      const extra = { admin: this.admin || undefined, uid: this.uid || undefined }
      premiumUpdate({ ...record, ...extra }).then((res) => {
        this.handleRes(res)
      })
    },
    updateEnable (record) {
      record.enable = !record.enable
      const extra = { admin: this.admin || undefined, uid: this.uid || undefined }
      premiumUpdate({ ...record, ...extra }).then((res) => {
        this.handleRes(res)
      })
    },
    handleOk () {
      const edit = this.edit
      if (edit.id) {
        const extra = { admin: this.admin || undefined, uid: this.uid || undefined }
        premiumUpdate({ ...edit, ...extra }).then((res) => {
          this.handleRes(res)
        })
      } else {
        const extra = { admin: this.admin || undefined, uid: this.uid || undefined }
        premiumInsert({ ...edit, ...extra }).then((res) => {
          this.handleRes(res)
        })
      }
    },
    handleRes (res) {
      if (res.success) {
        this.$message.success('成功')
        this.$refs.table.refresh(false)
        this.edit = { show: false }
      } else {
        this.$message.error(res.msg)
      }
    }
  },
  computed: {
    title () {
      return this.$route.meta.title
    }
  }
}
</script>

<style lang="less" scoped>
.top5 {
  margin-bottom: 8px;
}
</style>

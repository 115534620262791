<template>
  <div class="account-settings-info-view">
    <!--    <div>-->
    <!--      <span class="font">省</span>-->
    <!--      <a-input placeholder="省" v-model="address.province" />-->
    <!--    </div>-->
    <!--    <div class="font">-->
    <!--      <span>市</span>-->
    <!--      <a-input placeholder="市" v-model.trim="address.city" />-->
    <!--    </div>-->
    <!--    <div class="font">-->
    <!--      <span>详情</span>-->
    <!--      <a-input placeholder="详情" v-model.trim="address.detail" />-->
    <!--    </div>-->
    <div class="font">
      <span>姓名</span>
      <a-input placeholder="姓名" v-model.trim="address.name" />
    </div>
    <div class="font">
      <span>状态</span>
      <a-radio-group v-model="address.enabled" style="margin-left:8px">
        <a-radio :value="true">
          上架
        </a-radio>
        <a-radio :value="false">
          下架
        </a-radio>
      </a-radio-group>
    </div>
    <!--    <div class="font">-->
    <!--      <span>邮编</span>-->
    <!--      <a-input placeholder="邮编" v-model.trim="address.code" />-->
    <!--    </div>-->
    <!--    <div class="font">-->
    <!--      <span>手机号</span>-->
    <!--      <a-input placeholder="手机号" v-model.trim="address.mobile" />-->
    <!--    </div>-->
    <!--    <div class="font">-->
    <!--      <span>备注</span>-->
    <!--      <a-input placeholder="备注" v-model.trim="address.comment" />-->
    <!--    </div>-->
    <div class="font">
      <span>支持平台</span>
      <a-select
        mode="multiple"
        placeholder="请选择支持的平台"
        style="width: 100%"
        v-model="address.platform"
      >
        <a-select-option
          v-for="array in platformTypeArray"
          :key="array.value"
          >{{ array.name }}</a-select-option
        >
      </a-select>
    </div>
    <div class="font edit_item">
      <a-checkbox :checked="TAOBAO" @change="onChange($event, 'TAOBAO')">
        淘宝
      </a-checkbox>
      <a-input
        placeholder="对应平台淘宝的接口代码"
        v-model.trim="address.platformShopIdTb"
      />
    </div>
    <div class="font edit_item">
      <a-checkbox :checked="JD" @change="onChange($event, 'JD')">
        京东
      </a-checkbox>
      <a-input
        placeholder="对应平台京东的接口代码"
        v-model.trim="address.platformShopIdJd"
      />
    </div>
    <div class="font edit_item">
      <a-checkbox :checked="PDD" @change="onChange($event, 'PDD')">
        拼多多
      </a-checkbox>
      <a-input
        placeholder="对应平台拼多多的接口代码"
        v-model.trim="address.platformShopIdPdd"
      />
    </div>
    <div class="font edit_item">
      <a-checkbox :checked="DOUYIN" @change="onChange($event, 'DOUYIN')">
        抖音
      </a-checkbox>
      <a-input
        placeholder="对应平台抖音的接口代码"
        v-model.trim="address.platformShopIdDy"
      />
    </div>
    <div class="font edit_item">
      <a-checkbox :checked="ALIBABA" @change="onChange($event, 'ALIBABA')">
        阿里巴巴
      </a-checkbox>
      <a-input
        placeholder="对应平台阿里巴巴的接口代码"
        v-model.trim="address.platformShopIdAli"
      />
    </div>
    <div class="font edit_item">
      <a-checkbox :checked="XHS" @change="onChange($event, 'XHS')">
        小红书
      </a-checkbox>
      <a-input
        placeholder="对应平台小红书的接口代码"
        v-model.trim="address.platformShopIdXhs"
      />
    </div>
    <div class="font edit_item">
      <a-checkbox :checked="OTHER" @change="onChange($event, 'OTHER')">
        其他
      </a-checkbox>
      <a-input
        placeholder="对应其他的接口代码"
        v-model.trim="address.platformShopIdOther"
      />
    </div>
    <div class="font">
      <span>快递</span>
      <a-input
        placeholder="仓库快递公司分类"
        v-model.trim="address.waybillCompany"
      />
    </div>

    <div class="font">
      <span>注意事项</span>
      <a-textarea
        placeholder="填写地址与揽收时间等内容，将会展示与下单时选择的仓库"
        v-model="address.time"
        style="resize: none; height: 120px"
        :autoSize="{ minRows: 5 }"
      />
    </div>

    <div class="font">
      <span>分站（不可见）</span>
      <a-textarea
        placeholder="填写分站uid，回车键换行，一行一个~"
        v-model="address.noOems"
        style="resize: none; height: 120px"
        :autoSize="{ minRows: 5 }"
      />
    </div>
    <div class="font">
      <span>分站（可见）</span>
      <a-textarea
        placeholder="填写分站uid，回车键换行，一行一个~"
        v-model="address.oem"
        style="resize: none; height: 120px"
        :autoSize="{ minRows: 5 }"
      />
    </div>
    <div class="font">
      <span>用户（不可见）</span>
      <a-textarea
        placeholder="填写用户uid，回车键换行，一行一个~"
        v-model="address.uids"
        style="resize: none; height: 120px"
        :autoSize="{ minRows: 5 }"
      />
    </div>
    <div class="font">
      <span>用户（可见）</span>
      <a-textarea
        placeholder="填写用户uid，回车键换行，一行一个~"
        v-model="address.showUids"
        style="resize: none; height: 120px"
        :autoSize="{ minRows: 5 }"
      />
    </div>
    <div class="font">
      <span>禁发关键词</span>
      <a-textarea
        placeholder="可多个，用“,”英文逗号隔开"
        v-model="address.noSendKey"
        style="resize: none; height: 120px"
        :autoSize="{ minRows: 5 }"
      />
    </div>
    <div v-if="admin" class="font">
      <span>备注</span>
      <a-textarea
        placeholder="填写备注"
        v-model="address.comment"
        style="resize: none; height: 120px"
        :autoSize="{ minRows: 5 }"
      />
    </div>
    <div style="display: flex; align-items: center">
      <div style="height: 1px; background-color: #999999; flex: 1"></div>
      旧设置
      <div style="height: 1px; background-color: #999999; flex: 1"></div>
    </div>
    <div class="font">
      <span>发货平台类型</span>
      <a-select
        style="display: block; width: 100%"
        v-model="address.platformType"
      >
        <a-select-option value="MY">自营</a-select-option>
        <a-select-option value="CERP">CERP</a-select-option>
        <a-select-option value="JUSHUITAN">聚水潭</a-select-option>
        <a-select-option value="WLN">万里牛</a-select-option>
        <a-select-option value="LST">礼速通</a-select-option>
        <a-select-option value="JDF">就代发</a-select-option>
        <a-select-option value="JDF2">就代发2</a-select-option>
        <a-select-option value="TFM">TFM</a-select-option>
        <a-select-option value="YX">益鑫快递</a-select-option>
      </a-select>
    </div>
    <div class="font">
      <span>平台appId</span>
      <a-input placeholder="平台appId" v-model="address.platformAppId" />
    </div>
    <div class="font">
      <span>平台appKey</span>
      <a-input placeholder="平台appKey" v-model="address.platformAppKey" />
    </div>
    <div class="font">
      <span>平台url</span>
      <a-input placeholder="平台url" v-model="address.platformAppUrl" />
    </div>
    <div class="font">
      <span>平台token</span>
      <a-input placeholder="平台token" v-model="address.platformToken" />
    </div>

    <div class="font">
      <span>平台店铺ID</span>
      <a-input placeholder="平台店铺ID" v-model="address.platformShopId" />
    </div>
    <div class="font">
      <span>会员代码</span>
      <a-input placeholder="会员代码" v-model="address.platformUserCode" />
    </div>
    <div class="font">
      <span>仓库代码</span>
      <a-input placeholder="仓库代码" v-model="address.platformAddressCode" />
    </div>
    <div class="font">
      <span>快递代码</span>
      <a-input placeholder="快递代码" v-model="address.platformLogisticsCode" />
    </div>
    <div class="font">
      <span>监控单号获取</span>
      <a-radio-group v-model="address.active" style="margin-left:8px">
        <a-radio :value="true">
          是
        </a-radio>
        <a-radio :value="false">
          否
        </a-radio>
      </a-radio-group>
    </div>
    <a-button class="top" type="primary" @click="update">保存</a-button>
  </div>
</template>

<script>
import { PageView } from "@/layouts";
import { addressGet, addressUpdate } from "@/api";
import { jsonToArray, platformType } from "@/utils/util";

export default {
  /** 管理员操作时候调用该组件会传 admin uid仓库id */
  props: {
    admin: {
      type: Boolean,
      default: false
    },
    uid: {
      type: String,
      default: ""
    },
    curItem: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      platformTypeArray: jsonToArray(platformType),
      address: {},
      TAOBAO: false,
      JD: false,
      PDD: false,
      DOUYIN: false,
      ALIBABA: false,
      XHS: false,
      OTHER: false
    };
  },
  created() {
    if (this.admin) {
      this.address = JSON.parse(JSON.stringify(this.curItem));
      this.createData(this.curItem);
    } else {
      addressGet().then(res => {
        this.address = res.data;
        this.createData(res.data);
      });
    }
  },
  watch: {
    TAOBAO(newVal) {
      this.toggleCheck(newVal, "TAOBAO");
    },
    JD(newVal) {
      this.toggleCheck(newVal, "JD");
    },
    PDD(newVal) {
      this.toggleCheck(newVal, "PDD");
    },
    ALIBABA(newVal) {
      this.toggleCheck(newVal, "ALIBABA");
    },
    DOUYIN(newVal) {
      this.toggleCheck(newVal, "DOUYIN");
    },
    XHS(newVal) {
      this.toggleCheck(newVal, "XHS");
    },
    OTHER(newVal) {
      this.toggleCheck(newVal, "OTHER");
    }
  },
  methods: {
    createData(data) {
      if (this.address.oem) {
        this.address.oem = this.forMatStr(JSON.parse(data.oem));
      }
      if (this.address.noOems) {
        this.address.noOems = this.forMatStr(JSON.parse(data.noOems));
      }
      if (this.address.uids) {
        this.address.uids = this.forMatStr(JSON.parse(data.uids));
      }
      if (this.address.showUids) {
        this.address.showUids = this.forMatStr(JSON.parse(data.showUids));
      }
      if (data.platform != null) {
        this.address.platform = JSON.parse(data.platform);
      } else {
        this.address.platform = [];
      }
      ["TAOBAO", "JD", "PDD", "DOUYIN", "ALIBABA", "XHS", "OTHER"].forEach(
        item => {
          if (this.address.platform.indexOf(item) > -1) {
            this[item] = true;
          }
        }
      );
    },
    forMatStr(arr) {
      let str = "";
      arr.forEach((item, index) => {
        if (index == arr.length - 1) {
          str += item;
        } else {
          str += item + "\n";
        }
      });
      return str;
    },
    onChange(e, key) {
      this[key] = e.target.checked;
    },
    toggleCheck(val, key) {
      if (val) {
        if (this.address.platform.indexOf(key) < 0) {
          this.address.platform.push(key);
        }
      } else {
        this.address.platform = this.address.platform.filter(
          val => val !== key
        );
      }
    },
    update() {
      const address = JSON.parse(JSON.stringify(this.address));
      address.platform = JSON.stringify(this.address.platform);
      if (address.oem) {
        const arr = Array.from(new Set(address.oem.split("\n")));
        address.oem = JSON.stringify(arr);
      } else {
        address.oem = "[]";
      }
      if (address.noOems) {
        const arr = Array.from(new Set(address.noOems.split("\n")));
        address.noOems = JSON.stringify(arr);
      } else {
        address.noOems = "[]";
      }
      if (address.uids) {
        const arr = Array.from(new Set(address.uids.split("\n")));
        address.uids = JSON.stringify(arr);
      } else {
        address.uids = "[]";
      }
      if (address.showUids) {
        const arr = Array.from(new Set(address.showUids.split("\n")));
        address.showUids = JSON.stringify(arr);
      } else {
        address.showUids = "[]";
      }
      const extra = {
        admin: this.admin || undefined,
        uid: this.uid || undefined
      };
      addressUpdate({ ...address, ...extra }).then(res => {
        if (res.success) {
          this.$message.success("成功");
          this.$emit("success");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handlePlatformChange(platform) {
      this.address.platform = platform;
    }
  },
  components: {
    PageView
  }
};
</script>

<style lang="less" scoped>
.font {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 15px;
}
.edit_item {
  display: flex;
}
.top {
  margin-top: 10px;
}
/deep/ .ant-checkbox-wrapper {
  width: 100px;
  display: flex;
  align-items: center;
}
</style>
